// src/ComingSoon.js
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton, Menu, MenuItem, Stack, Switch, createTheme, useTheme, CssBaseline, ThemeProvider, Button } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import Brightness7 from '@mui/icons-material/Brightness7';
import NightlightRound from '@mui/icons-material/NightlightRound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin , faTiktok, faXTwitter ,faSnapchatSquare, faSnapchat, faTelegram, faThreads, faSnapchatGhost} from '@fortawesome/free-brands-svg-icons';
import './ComingSoon.css'; // Import the CSS file for animations
import SlideComponent from './SlideComponent';
import CountdownTimer from './CountDown';


const ComingSoon = () => {
  const [inProp, setInProp] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSlide, setShowSlide] = useState(false);

  const theme = useTheme();

 
  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const themeMode = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
    <Box sx={{ width: '100%' }}>
      <AppBar position="static" sx={{ width: '100%' }}>
        <Toolbar>
           <Typography  variant="h6" component="div" sx={{ flexGrow: 1 }}>BNaturo</Typography>
          
          <IconButton color="inherit" onClick={handleDarkModeToggle}>
            {darkMode ?   <Brightness7 /> :<NightlightRound />}
           </IconButton>           
         </Toolbar>
      </AppBar>
      <CSSTransition in={inProp} timeout={500} classNames="fade">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{
              fontSize: {
                xs: '2rem', // small devices
                sm: '3rem', // medium devices
                md: '4rem', // large devices
              },
            }}
          >
             “BNaturo🌿, Be You!
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{
              fontSize: {
                xs: '1rem', // small devices
                sm: '1.25rem', // medium devices
                md: '1.5rem', // large devices
              },
            }}
          >
     

     Whether you’re into a healthy diet, fitness, or natural beauty, we’ve got you covered. Stay tuned!<br/>
          </Typography> 
      
          {/* <Typography
            variant="h6"
            align="center"
            sx={{
              fontSize: {
                xs: '1rem', // small devices
                sm: '1.25rem', // medium devices
                md: '1.5rem', // large devices
              },
            }}
          >
     

           Hey wellness warriors! <br/>
           Whether you’re into fitness, beauty, or sports, we’ve got you covered.

          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{
              fontSize: {
                xs: '1rem', // small devices
                sm: '1.25rem', // medium devices
                md: '1.5rem', // large devices
              },
            }}
          >
     

     Ready to glow up naturally?  <br/>
     <b>BNaturo</b>  is launching soon with the freshest organic supplements for health, beauty, and sports. Stay tuned

          </Typography> */}
         
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem', // small devices
                sm: '1.5rem', // medium devices
                md: '2rem', // large devices
              },
            }}
          >
             <b>  Coming soon !</b>
          </Typography>
         

          <Box
            display="flex"
            justifyContent="center"
            mt={2}
            sx={{
              '& > *': {
                mx: 1,
                fontSize: {
                  xs: '1.5rem', // small devices
                  sm: '2rem', // medium devices
                  md: '2.5rem', // large devices
                },
              },
            }}
          >
             <IconButton href="https://tiktok.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faTiktok}  style={{ color: '#343131' }}/>
              </IconButton>
              <IconButton href="https://snapchat.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faSnapchatGhost}  style={{ color: '#FFD43B' }}/>
              </IconButton> 
           <IconButton href="https://facebook.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faFacebook} style={{ color: '#4267B2' }}/>
            </IconButton>
            <IconButton href="https://instagram.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faInstagram}  style={{ color: '#E1306C' }}/>
            </IconButton>
            <IconButton href="https://twitter.com" target="_blank" color="primary">
              <FontAwesomeIcon fill='#E1306C' icon={faXTwitter} style={{ color: '#000000' }}/>
            </IconButton>
              <IconButton href="https://linkedin.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faLinkedin} style={{ color: '#1DA1F2' }}/>
            </IconButton>
            <IconButton href="https://linkedin.com" target="_blank" color="primary">
            <FontAwesomeIcon icon={faThreads} style={{ color: '#000000' }}/>
            </IconButton>
              <IconButton href="https://linkedin.com" target="_blank" color="primary">
              <FontAwesomeIcon icon={faTelegram} style={{ color: '#1DA1F2' }}/>
            </IconButton>
          
          </Box>
        </Box>
      </CSSTransition>
      <Box
        component="footer"
        py={3}
        textAlign="center"
        borderTop={1}
        borderColor="grey.300"
        sx={{ width: '100%' }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 BNaturo. All rights reserved.
        </Typography>
      </Box>
    </Box>
     </ThemeProvider>

  );
};

export default ComingSoon;
