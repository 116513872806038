import React from 'react'
import './App.css'
import ComingSoon from './pages/ComingSoon'
  
function App () {
    return (
       <ComingSoon ></ComingSoon>
    )
}

export default App
