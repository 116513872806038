import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BASE_URL } from './Configuration/APP_CONFIG'
;(async () => {
    const LDProvider = await asyncWithLDProvider({
        // DEV
        // Should integrated in to pipeline
        // should be in env.json
        clientSideID: '6350fc3a648900117b0b4f9e',
        user: {
            key: 'MapFeature',
            name: 'Aref Khalil ',
            email: 'aref.khalil@hexagon.com'
        },
        options: {
            /* ... */
        }
    })

    const root = ReactDOM.createRoot(document.getElementById('root')!)
    root.render(
        <React.StrictMode>
            <LDProvider>
                <BrowserRouter basename={BASE_URL}>
                    <Routes>
                        <Route path='/' element={<App />}></Route>
                    </Routes>
                </BrowserRouter>
            </LDProvider>
        </React.StrictMode>
    )

    // // If you want to start measuring performance in your app, pass a function
    // // to log results (for example: reportWebVitals(console.log))
    // // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
})()
